import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const PoolItem = ({ item }) => {
  const image = item.image && !isEmpty(item.image.filename) ? item.image.filename : null
  const link = item.link && !isEmpty(item.link.url) ? item.link.url : null

  const [ref, inView] = useInView({
    triggerOnce: true
  })

  return (
    <motion.div
      ref={ref}
      className='col-6 col-md-3'
      animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0.8 }}
      transition={{ duration: 0.5, delay: 0.3, ease: 'backOut' }}
    >
      <a href={link} target={link ? '_blank' : null} rel='noreferrer' className='poolItem'>
        {image ? <img src={item.image.filename} alt={item.image.alt} className='mw-100' /> : <span>{item.title}</span>}
      </a>
    </motion.div>
  )
}

PoolItem.propTypes = {
  item: PropTypes.object
}

export default PoolItem
