import { sortBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import PoolItem from './PoolItem'

const PoolGroup = ({ content }) => {
  const sortedItems = sortBy(content.pool_items, ['title'])

  return (
    <div className='columns row'>
      {sortedItems.map(item => <PoolItem key={item._uid} item={item} />)}
    </div>
  )
}

PoolGroup.propTypes = {
  content: PropTypes.object
}

export default PoolGroup
