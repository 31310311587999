import PropTypes from 'prop-types'
import React from 'react'
import { generateStoryblokContent } from '../../utils/storyblok'

const Columns = ({ content }) => {
  return (
    <div className={`columns row columns-${content.countPerRow}`}>
      {content.block.map(item => (
        <div key={item._uid} className={`col-12 col-md-${12 / content.countPerRow}`}>
          {generateStoryblokContent(item)}
        </div>
      ))}
    </div>

  )
}

Columns.propTypes = {
  content: PropTypes.object
}

export default Columns
