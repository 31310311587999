import { motion } from 'framer-motion'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import RichText from './RichText'

const Teaser = ({ content }) => {
  const image = content.image && !isEmpty(content.image.filename) ? content.image.filename : null

  const [ref, inView] = useInView({
    triggerOnce: true
  })

  return (
    <div
      ref={ref}
      className='teaser mt-3 mb-3'
    >
      {image && (
        <motion.div
          className='image-container'
          animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0 }}
          transition={{ duration: 0.6, delay: 0.4, ease: 'backOut' }}
        >
          <img src={content.image.filename} alt={content.image.alt} className='mw-100' />
        </motion.div>
      )}
      {content.text && !isEmpty(content.text) && (
        <RichText>{content.text}</RichText>
      )}
    </div>
  )
}

Teaser.propTypes = {
  content: PropTypes.object
}

export default Teaser
