import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { generateStoryblokContent } from '../../utils/storyblok'

const Section = ({ content }) => {
  const anchor = !isEmpty(content.anchorTitle) ? encodeURIComponent(content.anchorTitle).toLowerCase() : null

  const backgroundImage = content.backgroundImage && !isEmpty(content.backgroundImage.filename) ? content.backgroundImage.filename : null

  const backgroundColor = !isEmpty(content.backgroundColor) && `background-${content.backgroundColor}`
  const textColor = !isEmpty(content.textColor) && `text-${content.textColor}`

  const className = !isEmpty(content.className) && `section-${content.className}`

  return (
    <section
      id={anchor}
      className={`${textColor} ${backgroundColor} ${className}`}
      style={backgroundImage && { backgroundImage: `url(${backgroundImage})` }}
    >
      <div className='container'>
        {generateStoryblokContent(content.block)}
      </div>
    </section>
  )
}

Section.propTypes = {
  content: PropTypes.object
}

export default Section
