import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import React from 'react'
import { useInView } from 'react-intersection-observer'

const PressItem = ({ item }) => {
  const [ref, inView] = useInView({
    triggerOnce: true
  })

  return (
    <motion.div
      ref={ref}
      className='col-12 col-lg-8'
      animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0.8 }}
      transition={{ duration: 0.5, delay: 0.3, ease: 'backOut' }}
    >
      <a href={item.url.cached_url} target='_blank' rel='noreferrer' className='pressItem'>
        <h2>{item.title}</h2>
        <p className='location'>{item.location}</p>
        <p className='date'>{new Date(item.date).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric' })}</p>
      </a>
    </motion.div>
  )
}

PressItem.propTypes = {
  item: PropTypes.object
}

export default PressItem
