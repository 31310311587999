import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import PressItem from './PressItem'

const PressGroup = ({ content }) => {
  const sortedItems = orderBy(content.items, ['date'], 'desc')

  return (
    <div className='columns row justify-content-center'>
      {sortedItems.map(item => <PressItem key={item._uid} item={item} />)}
    </div>
  )
}

PressGroup.propTypes = {
  content: PropTypes.object
}

export default PressGroup
