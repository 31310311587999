import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import React from 'react'
import InView from 'react-intersection-observer'
import { NODE_HEADING, NODE_IMAGE, render } from 'storyblok-rich-text-react-renderer'

const RichText = ({ children }) => (
  render(children, {
    nodeResolvers: {
      [NODE_HEADING]: (children, props) => (
        props.level === 4
          ? (
            <InView triggerOnce>
              {({ inView, ref }) => (
                <motion.h4 ref={ref} className={inView ? 'animate' : ''}>{children}</motion.h4>
              )}
            </InView>
            )
          : props.level === 1
            ? (
              <InView triggerOnce>
                {({ inView, ref }) => (
                  <motion.div
                    ref={ref}
                    animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 1.1 }}
                    transition={{ duration: 1, delay: 0.3, ease: 'easeOut' }}
                  >
                    <motion.h1 className={inView ? 'animate' : ''}>{children}</motion.h1>
                  </motion.div>
                )}
              </InView>
              )
            : React.createElement(`h${props.level}`, null, children)
      ),
      // [NODE_PARAGRAPH]: (children) => (
      //   <InView triggerOnce>
      //     {({ inView, ref }) => (
      //       <motion.p
      //         ref={ref}
      //         animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '5%' }}
      //         transition={{ duration: 0.4, delay: 0.3, ease: 'easeOut' }}
      //       >
      //         {children}
      //       </motion.p>
      //     )}
      //   </InView>
      // ),
      // [NODE_UL]: (children) => (
      //   <InView triggerOnce>
      //     {({ inView, ref }) => (
      //       <motion.ul
      //         ref={ref}
      //         animate={{ opacity: inView ? 1 : 0 }}
      //         transition={{ duration: 0.4, delay: 0.3, ease: 'easeOut' }}
      //       >
      //         {children}
      //       </motion.ul>
      //     )}
      //   </InView>
      // ),
      [NODE_IMAGE]: (children, props) => (
        <InView triggerOnce>
          {({ inView, ref }) => (
            <motion.img
              ref={ref}
              {...props}
              animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0.8 }}
              transition={{ duration: 0.6, delay: 0.3, ease: 'backOut' }}
            >
              {children}
            </motion.img>
          )}
        </InView>
      )
    }
  })
)

RichText.propTypes = {
  children: PropTypes.object
}

export default RichText
