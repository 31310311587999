import React from 'react'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { generateStoryblokContent } from '../../utils/storyblok'

const Page = ({ pageContext: { story }, location }) => {
  const body = JSON.parse(story.content).body

  return (
    <Layout location={location}>
      <Seo title={story.name} />
      {generateStoryblokContent(body)}
    </Layout>
  )
}

export default Page
