import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import InlineSVG from 'react-inlinesvg'
import { useInView } from 'react-intersection-observer'
import RichText from './RichText'

const Circulation = ({ content }) => {
  const image = typeof window !== 'undefined' ? require('../../images/kreislauf.svg').default : content.circle_image && !isEmpty(content.circle_image.filename) ? content.circle_image.filename : null

  const [ref, inView] = useInView({
    triggerOnce: true
  })

  return (
    <div id='circulation' className='row align-items-center'>
      <div className='col-12 col-lg-4 text-center'>
        {content.rich_text && !isEmpty(content.rich_text) && (
          <RichText>{content.rich_text}</RichText>
        )}
      </div>
      <div className='col-12 col-lg-8'>
        {image && (
          <div ref={ref} className='image-container'>
            <InlineSVG src={image} width='100%' className={inView && 'animate'} />
          </div>
        )}
      </div>
    </div>
  )
}

Circulation.propTypes = {
  content: PropTypes.object
}

export default Circulation
