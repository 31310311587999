import { motion } from 'framer-motion'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useInView } from 'react-intersection-observer'
import RichText from './RichText'

const SectionContact = ({ content }) => {
  const anchor = !isEmpty(content.anchorTitle) ? encodeURIComponent(content.anchorTitle).toLowerCase() : null
  const image = content.image && !isEmpty(content.image.filename) ? content.image.filename : null

  const [dropdownText, setDropdownText] = useState()

  const [ref, inView] = useInView({
    triggerOnce: true
  })

  return (
    <section
      id={anchor}
      className='background-white p-0'
    >
      <div className='container'>
        <div className='row contact'>
          <div className='col-12 col-lg-5'>
            <div className='background-green text-white'>
              {image && <div className='image-container'><img src={content.image.filename} alt={content.image.alt} className='mw-100' /></div>}
              {content.rich_text && !isEmpty(content.rich_text) && <div className='pt-4 pl-4 pr-4 pb-3'><RichText>{content.rich_text}</RichText></div>}
            </div>
          </div>
          <div className='col-12 col-lg-7 d-flex align-items-center'>
            <motion.div
              ref={ref}
              className='pt-5 pb-5 pl-0 pl-md-5'
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '2%' }}
              transition={{ duration: 0.8, delay: 0.4, ease: 'easeOut' }}
            >
              <h1 className={inView ? 'animate' : ''}>{content.anchorTitle}</h1>
              <form name='Kontaktformular' method='POST' data-netlify='true' action='/success'>
                <input type='hidden' name='form-name' value='Kontaktformular' />
                <div className='form-row mb-4'>
                  <div className='col'>
                    <input name='firstname' type='name' className='form-control' placeholder='Vorname *' required />
                  </div>
                  <div className='col'>
                    <input name='lastname' type='name' className='form-control' placeholder='Nachname *' required />
                  </div>
                </div>
                <div className='form-row mb-4'>
                  <div className='col'>
                    <input name='email' type='email' className='form-control' placeholder='E-Mail *' required />
                  </div>
                  <div className='col'>
                    <input name='phone' type='phone' className='form-control' placeholder='Telefon' />
                  </div>
                </div>
                <div className='form-row mb-4'>
                  <div className='col'>
                    <input name='subject' type='text' required value={dropdownText} className='d-none' />
                    <Dropdown>
                      <Dropdown.Toggle id='dropdown-anliegen'>
                        {!isEmpty(dropdownText) ? dropdownText : 'Ihr Anliegen *'}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setDropdownText('Ich habe Fragen an die GeMeMa')}>Ich habe Fragen an die GeMeMa</Dropdown.Item>
                        <Dropdown.Item onClick={() => setDropdownText('Ich möchte Mitglied werden')}>Ich möchte Mitglied werden</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className='form-row mb-4'>
                  <div className='col'>
                    <textarea name='message' className='form-control' rows='7' placeholder='Ihre Nachricht *' required />
                  </div>
                </div>
                <div className='form-row mb-4'>
                  <div className='col'>
                    <div className='form-check mb-4'>
                      <label className='form-check-label'><input type='checkbox' className='form-check-input' required /> Ich akzeptiere die Datenschutzbestimmungen. *</label>
                    </div>
                    <button type='submit' className='btn btn-primary'>Senden</button>
                  </div>
                </div>
              </form>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  )
}

SectionContact.propTypes = {
  content: PropTypes.object
}

export default SectionContact
