import { motion, useTransform, useViewportScroll } from 'framer-motion'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

const Header = ({ content }) => {
  const backgroundImage = content.backgroundImage ? content.backgroundImage.filename : null
  const textColor = !isEmpty(content.textColor) && `text-${content.textColor}`

  const ref = useRef(null)
  const [elementTop, setElementTop] = useState(0)
  const [elementHeight, setElementHeight] = useState(0)

  const { scrollY } = useViewportScroll()

  const transformInitialValue = elementTop
  const transformFinalValue = elementTop + (elementHeight * 0.7)

  const opacity = useTransform(scrollY, [transformInitialValue, transformFinalValue], [1, 0])

  const setValues = () => {
    if (!ref.current) return
    setElementTop(ref.current.offsetTop)
    setElementHeight(ref.current.scrollHeight)
  }

  useEffect(() => {
    setValues()
    document.addEventListener('load', setValues)
    window.addEventListener('resize', setValues)

    setTimeout(setValues, 1000)

    return () => {
      document.removeEventListener('load', setValues)
      window.removeEventListener('resize', setValues)
    }
  }, [ref?.current])

  useLayoutEffect(() => {
    setValues()
  })

  return (
    <motion.section
      ref={ref} className='header d-flex align-items-end' style={{
        backgroundImage: `url(${backgroundImage})`
      }}
    >
      <motion.div className='container text-center' style={{ opacity }}>
        {content.headline && !isEmpty(content.headline) && <h1 className={`${textColor} animate`}>{content.headline}</h1>}
        {content.image.filename && (
          <img
            src={content.image.filename}
            alt={content.image.alt}
            width={200}
          />
        )}
      </motion.div>
    </motion.section>
  )
}

Header.propTypes = {
  content: PropTypes.object
}

export default Header
