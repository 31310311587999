import React from 'react'
import Circulation from '../components/storyblok/Circulation'
import Columns from '../components/storyblok/Columns'
import Header from '../components/storyblok/Header'
import Image from '../components/storyblok/Image'
import PoolGroup from '../components/storyblok/PoolGroup'
import PressGroup from '../components/storyblok/PressGroup'
import RichText from '../components/storyblok/RichText'
import Section from '../components/storyblok/Section'
import SectionContact from '../components/storyblok/SectionContact'
import Teaser from '../components/storyblok/Teaser'

export const generateStoryblokContent = (content) => {
  const contentArray = Array.isArray(content) ? content : [content]

  return (
    contentArray.map((item) => {
      switch (item.component) {
        case 'header':
          return <Header key={item._uid} content={item} />
        case 'section':
          return <Section key={item._uid} content={item} />
        case 'rich_text':
          return <RichText key={item._uid}>{item.body}</RichText>
        case 'teaser':
          return <Teaser key={item._uid} content={item} />
        case 'columns':
          return <Columns key={item._uid} content={item} />
        case 'image':
          return <Image key={item._uid} content={item} />
        case 'pool_group':
          return <PoolGroup key={item._uid} content={item} />
        case 'press_group':
          return <PressGroup key={item._uid} content={item} />
        case 'section_contact':
          return <SectionContact key={item._uid} content={item} />
        case 'circulation':
          return <Circulation key={item._uid} content={item} />
        default:
          return null
      }
    })
  )
}
