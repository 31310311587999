import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

const Image = ({ content }) => {
  const image = content.image && !isEmpty(content.image.filename) ? content.image.filename : null
  const cssClass = !isEmpty(content.cssClass) ? 'img-' + content.cssClass : ''
  const backgroundColor = !isEmpty(content.backgroundColor) && `background-${content.backgroundColor}`
  const container = content.container ? 'container' : ''

  return (
    <div className={`imageSection ${cssClass} ${backgroundColor}`}>
      <div className={container}>
        <div className='image'>
          {image && <img src={content.image.filename} alt={content.image.alt} />}
        </div>
      </div>
    </div>
  )
}

Image.propTypes = {
  content: PropTypes.object
}

export default Image
